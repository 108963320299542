import "./congratulation.scss";

import React from "react";
// import Confetti from "react-confetti";
import { useEffect } from "react";
import confetti from "canvas-confetti";

export default function Congratulation({ winningNumber, isWinner }) {
  useEffect(() => {
    if (isWinner) {
      var duration = 5 * 1000;
      var end = Date.now() + duration;

      const frame = () => {
        // launch a few confetti from the left edge
        confetti({
          particleCount: 5,
          angle: 60,
          spread: 150,
          origin: { x: 0 },
        });
        // and launch a few from the right edge
        confetti({
          particleCount: 5,
          angle: 120,
          spread: 150,
          origin: { x: 1 },
        });

        // keep going until we are out of time
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      };
      frame();
    }
  }, []); //eslint-disable-line

  return (
    <div className="congratulation">
      {isWinner ? (
        <div className="text">
          Winning Number: {winningNumber} <br />
          Congratulations! <br />
          You're the winner
        </div>
      ) : (
        <div className="no-winner">
          Winning Number: {winningNumber} <br />
          You didn't win this time! <br />
          Enter the next draw and try again.
        </div>
      )}

      {/* <div className='confetti'>
        <Confetti/>
      </div> */}
    </div>
  );
}
