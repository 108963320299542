import "./header.scss";

import React from "react";
import { useAccount, useChainState } from "@vechain.energy/use-vechain";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import ProfileModal from "../../components/ProfileModal/ProfileModal";
import { getMyTicketNumber, getPlayerNumber, WoVBalanceOf, getDepositeAmount } from "../../utils/vechain";
import config from '../../config/config.json'
import { useDispatch } from "react-redux";
import { setPrizePot } from "../../slices/prizePotSlice";
import { setMyTickets } from "../../slices/myTicketsSlice";
import { setTotalTicketCnt } from "../../slices/totalTicketCntSlice";

export default function Header() {
  const [popupShow, setPopupShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false)

  const connectBtnRef = useRef()

  const { connex } = useChainState()
  const { account, connect, disconnect } = useAccount();
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if(!connectBtnRef.current.contains(e.target)) {
        setPopupShow(false)
      }
    })
  }, [])

  useEffect(() => {
    init()
  }, [connex, account]) //eslint-disable-line

  const handleConnect = () => {
    if (account) {
      // disconnect();
      setPopupShow(true)
    } else {
      connect();
    }
  };

  const init = async () => {
    if(connex) {
      
      let b = await WoVBalanceOf(config.lotteryAddress, connex)
      let c = await getDepositeAmount(connex)
      dispatch(setPrizePot(((b - c) * 0.8 + c).toFixed(1)))
      
      let total = await getPlayerNumber(connex)      
      dispatch(setMyTickets(await getMyTicketNumber(account, total, connex)))
      dispatch(setTotalTicketCnt(total))
    }
  }

  const shortenAddress = (addr) => {
    let len = addr.length;
    return addr.slice(0, 4) + "..." + addr.slice(len - 4, len);
  };

  return (
    <header>
      <div className="logo">
        <img src="/images/velottery__logo2--white.png" alt="logo" />
      </div>

      <a className="buy-wov-button" href="https://vexchange.io/swap?outputCurrency=0x170F4BA8e7ACF6510f55dB26047C83D13498AF8A" target="_blank" rel="noreferrer">Buy WoV</a>
      <div className="connect-button">
        <button onClick={handleConnect} ref={connectBtnRef}>
          {account ? shortenAddress(account) : "Connect"}
        </button>
        {popupShow && (
          <div className="popup-wrapper">
            <div className="popup">
              <button onClick={() => setProfileShow(true)}>
                <i className="fa-regular fa-user"></i>
                <div>User Dashboard</div>
              </button>
              <button onClick={disconnect}>
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                <div>Disconnect</div>
              </button>
            </div>
          </div>
        )}
      </div>
      <ProfileModal
        isOpen={profileShow}
        onRequestClose={() => setProfileShow(false)}
      />
      
    </header>
  );
}
