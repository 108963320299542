import Layout from './layouts/Layout/Layout'
import './assets/styels/style.scss'
import Banner from './components/Banner/Banner';
import GetTicket from './components/GetTicket/GetTicket';

function App() {
  return (
    <Layout>
      <Banner />
      <GetTicket />
    </Layout>
  );
}

export default App;
