/* global BigInt */

import config from "../config/config.json";
import {
  parseUnits
} from "@ethersproject/units";

export const WoVBalanceOf = (account, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const balanceOfAbi = {
        constant: true,
        inputs: [{
          name: "_owner",
          type: "address",
        }, ],
        name: "balanceOf",
        outputs: [{
          name: "balance",
          type: "uint256",
        }, ],
        payable: false,
        stateMutability: "view",
        type: "function",
      };

      const balanceOfMethod = connex.thor
        .account(config.tokenAddress)
        .method(balanceOfAbi);
      let result = await balanceOfMethod.call(account);
      let balance = 0;
      if (result.decoded[0].length > 18) {
        balance = parseInt(result.decoded[0].slice(0, -18))
      } else {
        balance = 0
      }
      console.log("BalanceOf: " + result.decoded[0].slice(0, -18));
      // console.log()
      // let balance = 0;
      // let balance = (
      //   BigInt(result.decoded[0]) / BigInt(Math.pow(10, 12))
      // ).toString();
      // console.log(balance)
      // balance = parseInt(balance) / 1000000;

      if (balance - parseInt(balance) > 0.001) {
        balance = balance.toFixed(2);
      }
      resolve(balance);
    } catch (error) {
      console.log("WoVBalanceOf: ", error);
      resolve(0);
    }
  });

export const getDepositeAmount = (connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [],
        name: "depositedAmount",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256"
        }],
        stateMutability: "view",
        type: "function"
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      // console.log("depositedAmount:")
      // console.log(result.decoded[0])
      console.log(result.decoded[0] / 1000000000000)
      let depositedAmount = (
        BigInt(result.decoded[0]) / BigInt(Math.pow(10, 12))
      ).toString();
      console.log(depositedAmount)
      depositedAmount = parseInt(depositedAmount) / 1000000;
      resolve(depositedAmount);
    } catch (error) {
      console.log("depositedAmount: ", error);
      resolve(1);
    }
  });


export const getTicketPrice = (connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [],
        name: "entryPrice",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      // console.log("price:")
      // console.log(result)
      let price = (
        BigInt(result.decoded[0]) / BigInt(Math.pow(10, 12))
      ).toString();
      price = parseInt(price) / 1000000;
      resolve(price);
    } catch (error) {
      console.log("getTicketPrice: ", error);
      resolve(1);
    }
  });

export const getLotteryEndDate = (connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [],
        name: "lotteryEndDate",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      let endDate = parseInt(result.decoded[0]);
      resolve(endDate * 1000);
    } catch (error) {
      console.log("getLotteryEndDate: ", error);
      resolve(Date.now());
    }
  });

export const getWinner = (round, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        name: "recentWinners",
        outputs: [{
          internalType: "address",
          name: "",
          type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call(round - 1);
      console.log(result);
      if (result.decoded[0] === undefined) {
        resolve("");
      }
      resolve(result.decoded[0]);
    } catch (error) {
      console.log("getWinner: ", error);
      resolve("");
    }
  });

export const getPlayerNumber = (connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [],
        name: "currentTicketCnt",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      resolve(parseInt(result.decoded[0]));
    } catch (error) {
      console.log("getPlayerNumber: ", error);
      resolve(0);
    }
  });

export const getMyTicketNumber = (account, totalNumber, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256"
        }],
        name: "currentTickets",
        outputs: [{
            internalType: "address",
            name: "player",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "ticketNumber",
            type: "uint256"
          },
        ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let tn = [];
      for (let i = 0; i < totalNumber; i++) {
        let result = await method.call(i);
        if (account === result.decoded.player) {
          tn.push(parseInt(result.decoded.ticketNumber));
        }
      }
      resolve(tn);
    } catch (error) {
      console.log("getMyTicketNumber: ", error);
      resolve([]);
    }
  });

export const getAllowance = (owner, spender, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [{
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };
      const method = connex.thor.account(config.tokenAddress).method(abi);
      let result = await method.call(owner, spender);
      let allowance = (
        BigInt(result.decoded[0]) / BigInt(Math.pow(10, 12))
      ).toString();
      allowance = parseInt(allowance) / 1000000;
      resolve(allowance);
    } catch (error) {
      console.log("getAllowance: ", error);
      resolve(0);
    }
  });

export const getTotalRound = (connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [],
        name: "roundCounter",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      let round = parseInt(result.decoded[0]);
      resolve(round);
    } catch (error) {
      console.log("getTotalRound: ", error);
      resolve(0);
    }
  });

export const getUserProfile = (account, connex) =>
  new Promise(async (resolve) => {
    try {
      const abi = {
        inputs: [{
          internalType: "address",
          name: "",
          type: "address",
        }, ],
        name: "players",
        outputs: [{
            internalType: "uint256",
            name: "lastPurchasedTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalJackpotWon",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalTicketPurchased",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gamesPlayed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gamesWon",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call(account);
      resolve({
        gamesPlayed: result.decoded.gamesPlayed,
        gamesWon: result.decoded.gamesWon,
        totalTicketPurchased: result.decoded.totalTicketPurchased,
        totalJackpotWon: parseInt(
          (
            BigInt(result.decoded.totalJackpotWon) / BigInt(Math.pow(10, 16))
          ).toString()
        ) / 100,
      });
    } catch (error) {
      console.log("getUserProfile: ", error);
      resolve({
        gamesPlayed: 0,
        gamesWon: 0,
        totalTicketPurchased: 0,
        totalJackpotWon: 0,
      });
    }
  });

export const getTotalPlayers = (connex) =>
  new Promise(async (resolve) => {
    try {
      let abi = {
        inputs: [],
        name: "totalPlayers",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };
      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      resolve(parseInt(result.decoded[0]));
    } catch (error) {
      console.log("getTotalPlayers: ", error);
      resolve(0);
    }
  });

export const getTotalJackpot = (connex) =>
  new Promise(async (resolve) => {
    try {
      let abi = {
        inputs: [],
        name: "totalJackpot",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };
      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      resolve(
        parseInt(
          (BigInt(result.decoded[0]) / BigInt(Math.pow(10, 16))).toString()
        ) / 100
      );
    } catch (error) {
      console.log("getTotalJackpot: ", error);
      resolve(0);
    }
  });

export const getTotalRewardCnt = (connex) =>
  new Promise(async (resolve) => {
    try {
      let abi = {
        inputs: [],
        name: "totalRewardCnt",
        outputs: [{
          internalType: "uint256",
          name: "",
          type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
      };
      const method = connex.thor.account(config.lotteryAddress).method(abi);
      let result = await method.call();
      // console.log(result)
      resolve(parseInt(BigInt(result.decoded[0])));
    } catch (error) {
      console.log("getTotalRewardCnt: ", error);
      resolve(0);
    }
  });

export const getRewardEventHistory = (from, cnt, connex) =>
  new Promise(async (resolve) => {
    try {
      const abi = {
        anonymous: false,
        inputs: [{
            indexed: false,
            internalType: "uint256",
            name: "roundId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "ticketNumber",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "player",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "rewardSize",
            type: "uint256",
          },
        ],
        name: "revealResults",
        type: "event",
      };
      // console.log(config.lotteryAddress)
      const acc = connex.thor.account(config.lotteryAddress);
      const event = acc.event(abi);

      // 0x248888a841d35b6F916349A4b5D3945318f83E13

      // console.log(cnt)
      const filter = event.filter([]);

      filter.apply(from, cnt).then((logs) => {
        // console.log(logs.slice(14))
        let logsV2 = []
        logsV2.push({
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000003c"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000ab653861784f38a9cee2537e2e9d9ddce006d05d0000000000000000000000000000000000000000000010c570cb5c6a79800000",
          "meta": {
            "blockID": "0x00d2fad2608f603a909ceb417030cb3570d6f71ea9a4d23e8f0f2fa8d6b33585",
            "blockNumber": 13826770,
            "blockTimestamp": 1668790640,
            "txID": "0xb2415ade897fa21454829df2f0a896989001f911596cbc2431c70f93c34cac41",
            "txOrigin": "0x32d62ea489b43d33b72be35fbe5035fefd749022",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "2",
            "1": "60",
            "2": "0xab653861784f38a9cee2537e2e9d9ddce006d05d",
            "3": "79200000000000000000000",
            "roundId": "2",
            "ticketNumber": "60",
            "player": "0xab653861784f38a9cee2537e2e9d9ddce006d05d",
            "rewardSize": "79200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000007"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000030000000000000000000000007f94c17a24d4a6b131e71cd8aea3ad43196497fa0000000000000000000000000000000000000000000009d3595ab2438d000000",
          "meta": {
            "blockID": "0x00d2fad2608f603a909ceb417030cb3570d6f71ea9a4d23e8f0f2fa8d6b33585",
            "blockNumber": 13826770,
            "blockTimestamp": 1668790640,
            "txID": "0xb2415ade897fa21454829df2f0a896989001f911596cbc2431c70f93c34cac41",
            "txOrigin": "0x32d62ea489b43d33b72be35fbe5035fefd749022",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "3",
            "1": "7",
            "2": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "3": "46400000000000000000000",
            "roundId": "3",
            "ticketNumber": "7",
            "player": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "rewardSize": "46400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000005f"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000004000000000000000000000000ba97c676a79f3c8d0f772e6ce9d793a07a3cf0d40000000000000000000000000000000000000000000015af1d78b58c40000000",
          "meta": {
            "blockID": "0x00d2fad2608f603a909ceb417030cb3570d6f71ea9a4d23e8f0f2fa8d6b33585",
            "blockNumber": 13826770,
            "blockTimestamp": 1668790640,
            "txID": "0xb2415ade897fa21454829df2f0a896989001f911596cbc2431c70f93c34cac41",
            "txOrigin": "0x32d62ea489b43d33b72be35fbe5035fefd749022",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "4",
            "1": "95",
            "2": "0xba97c676a79f3c8d0f772e6ce9d793a07a3cf0d4",
            "3": "102400000000000000000000",
            "roundId": "4",
            "ticketNumber": "95",
            "player": "0xba97c676a79f3c8d0f772e6ce9d793a07a3cf0d4",
            "rewardSize": "102400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000018"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000050000000000000000000000007f94c17a24d4a6b131e71cd8aea3ad43196497fa000000000000000000000000000000000000000000000a80d24677efef000000",
          "meta": {
            "blockID": "0x00d2ff3a3bc09a311831a4d2ed5fb3215b7e281c394e40a97297dfc29f60dc4d",
            "blockNumber": 13827898,
            "blockTimestamp": 1668801920,
            "txID": "0xbc4751c3ffeef998b1031a63cff18bf801cff722344e95308aedf531b47ffa82",
            "txOrigin": "0x32d62ea489b43d33b72be35fbe5035fefd749022",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "5",
            "1": "24",
            "2": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "3": "49600000000000000000000",
            "roundId": "5",
            "ticketNumber": "24",
            "player": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "rewardSize": "49600000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000001"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000060000000000000000000000007f94c17a24d4a6b131e71cd8aea3ad43196497fa000000000000000000000000000000000000000000000e0f8d1c45b8f1800000",
          "meta": {
            "blockID": "0x00d32263ae99981d4cd847e59b1d65985e3f101e1aac449052cffc64c19a1ba4",
            "blockNumber": 13836899,
            "blockTimestamp": 1668891930,
            "txID": "0x254d3bf29a6b4f067253aa51e0e90b73201b5df0cc7d290e3eacb48a1492f725",
            "txOrigin": "0x32d62ea489b43d33b72be35fbe5035fefd749022",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "6",
            "1": "1",
            "2": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "3": "66400000000000000000000",
            "roundId": "6",
            "ticketNumber": "1",
            "player": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "rewardSize": "66400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000037"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000070000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a43861000000000000000000000000000000000000000000001172e9b72216db800000",
          "meta": {
            "blockID": "0x00d3429c09209946277dbe2d94dc13b3bb0dea507d180307826b6f3c164b2b97",
            "blockNumber": 13845148,
            "blockTimestamp": 1668974430,
            "txID": "0x92e103d41df07a6a2e943f5b1fcd20b632ec80159182dbfd7b44bf1732ff337e",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "7",
            "1": "55",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "82400000000000000000000",
            "roundId": "7",
            "ticketNumber": "55",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "82400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000001d"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000080000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a43861000000000000000000000000000000000000000000000b02ecf74c3138800000",
          "meta": {
            "blockID": "0x00d3648cfe704f4b0cdca2eaf1febdc2ceba3815b2cf7b7c057bfb4ab8841f59",
            "blockNumber": 13853836,
            "blockTimestamp": 1669061310,
            "txID": "0x621747d16bd7194f3ccc7d0a34904deec173fdab45ccee76c091da6f0dec40b8",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "8",
            "1": "29",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "52000000000000000000000",
            "roundId": "8",
            "ticketNumber": "29",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "52000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000003d"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000000b000000000000000000000000026004eb68786bc9d1bda45aaf10f9abdbf33934000000000000000000000000000000000000000000000b2e4b323d9c51000000",
          "meta": {
            "blockID": "0x00d38615b03bcceccee84a43b9b0f8b29a0e19e4e511ad510fe7df5c0fe3a1be",
            "blockNumber": 13862421,
            "blockTimestamp": 1669147160,
            "txID": "0xf2f61055f0adf82f766aca979ece1c2489f09d8dc1807cb1925c8f3812f21f9b",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "11",
            "1": "61",
            "2": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "3": "52800000000000000000000",
            "roundId": "11",
            "ticketNumber": "61",
            "player": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "rewardSize": "52800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000019"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000000d000000000000000000000000026004eb68786bc9d1bda45aaf10f9abdbf3393400000000000000000000000000000000000000000000097c9ce4cf6d5c000000",
          "meta": {
            "blockID": "0x00d3a7d541f41be30c3b7e2e8990e23ff83619031e0ad04a02fd7849907526bf",
            "blockNumber": 13871061,
            "blockTimestamp": 1669233560,
            "txID": "0xbf61a182d5789fa4b166b72c570e632a1d6bd361942cb72affe481561191cfc2",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "13",
            "1": "25",
            "2": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "3": "44800000000000000000000",
            "roundId": "13",
            "ticketNumber": "25",
            "player": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "rewardSize": "44800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000009"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000000f0000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc890000000000000000000000000000000000000000000005150ae84a8cdf000000",
          "meta": {
            "blockID": "0x00d3c99536acde02d6823e3994fbd2e0534d409f175ef8265c3d4ecbfda2ccd5",
            "blockNumber": 13879701,
            "blockTimestamp": 1669319960,
            "txID": "0x14a749c5aef3aec5dec9adb813a02b3cfce200d18ca8e5b60ca44af70c4f07a6",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "15",
            "1": "9",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "24000000000000000000000",
            "roundId": "15",
            "ticketNumber": "9",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "24000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000002"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000100000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a438610000000000000000000000000000000000000000000005c283d4103941000000",
          "meta": {
            "blockID": "0x00d3eb57bfc6a81a61d940754cfd32ec219f4ac06c0cb22a15a45527b20f4c5b",
            "blockNumber": 13888343,
            "blockTimestamp": 1669406380,
            "txID": "0x6bc08b6f1b1954e755c49e6886b2ab66f8aff81d4a97e71d4c7daf0e35d1d024",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "16",
            "1": "2",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "27200000000000000000000",
            "roundId": "16",
            "ticketNumber": "2",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "27200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000c"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000110000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a438610000000000000000000000000000000000000000000006194049f30f72000000",
          "meta": {
            "blockID": "0x00d40d15897656903e7372fb7a49c92713087bd2150f9cb486edc41291192307",
            "blockNumber": 13896981,
            "blockTimestamp": 1669492760,
            "txID": "0x1724ef37ff0a74d18f4de93d726a0a6ca9f74fc01c92bfa4febfcbd035c4e5d8",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "17",
            "1": "12",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "28800000000000000000000",
            "roundId": "17",
            "ticketNumber": "12",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "28800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000130000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a438610000000000000000000000000000000000000000000001b1ae4d6e2ef5000000",
          "meta": {
            "blockID": "0x00d42ed5939ced22e1fd9fcd6a96ff2b13081653ef3976c611a90638c3f2ca64",
            "blockNumber": 13905621,
            "blockTimestamp": 1669579160,
            "txID": "0xe432797bdc312de29f050f4f13c79022b055464571a4f37061f1e3746c5ffe7e",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "19",
            "1": "5",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "8000000000000000000000",
            "roundId": "19",
            "ticketNumber": "5",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "8000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000150000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc89000000000000000000000000000000000000000000000aac3081695b07800000",
          "meta": {
            "blockID": "0x00d45095379c516dc121941fca8e823fa210412e7dbb0168bbdb1be49e5369e0",
            "blockNumber": 13914261,
            "blockTimestamp": 1669665560,
            "txID": "0x5a2c7c006076df30c78627fbe356eff7d143be21018522f8e6d9d46de7408fab",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "21",
            "1": "5",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "50400000000000000000000",
            "roundId": "21",
            "ticketNumber": "5",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "50400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000003"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000016000000000000000000000000c543f9445e07e12f0bfce76a6e1bfff81d812b5c00000000000000000000000000000000000000000000018650127cc3dc800000",
          "meta": {
            "blockID": "0x00d4725440c121879cae7c936f0df78520dcd6796ae2aed146ed612ab2ea05bc",
            "blockNumber": 13922900,
            "blockTimestamp": 1669751960,
            "txID": "0xa931111fee8703c245c5f39cb90b9de5c6d490ede43e51e412184f587955b67a",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "22",
            "1": "3",
            "2": "0xc543f9445e07e12f0bfce76a6e1bfff81d812b5c",
            "3": "7200000000000000000000",
            "roundId": "22",
            "ticketNumber": "3",
            "player": "0xc543f9445e07e12f0bfce76a6e1bfff81d812b5c",
            "rewardSize": "7200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000a"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000018000000000000000000000000026004eb68786bc9d1bda45aaf10f9abdbf339340000000000000000000000000000000000000000000001dd0c885f9a0d800000",
          "meta": {
            "blockID": "0x00d49414b14c020c5ad92d4846e48b7a3bc3acb8ec3119de616165a85fbef963",
            "blockNumber": 13931540,
            "blockTimestamp": 1669838360,
            "txID": "0xeaf4d6fef566d1ac60e16a332d6171be52c31351c5db6c36b24a97ae4f77f9ec",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "24",
            "1": "10",
            "2": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "3": "8800000000000000000000",
            "roundId": "24",
            "ticketNumber": "10",
            "player": "0x026004eb68786bc9d1bda45aaf10f9abdbf33934",
            "rewardSize": "8800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000007"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000001b0000000000000000000000000d85c6375fd2997a1d77a60cd9cfb378c01042900000000000000000000000000000000000000000000004be4e7267b6ae000000",
          "meta": {
            "blockID": "0x00d4d794b124bac7f38ec9639c82345dbdb314c213cfc90f498307b4e9c7454d",
            "blockNumber": 13948820,
            "blockTimestamp": 1670011160,
            "txID": "0xcc4a02d7a86034577a44d5d1e84419ee02c902bf5808983266d0fe93f5014c75",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "27",
            "1": "7",
            "2": "0x0d85c6375fd2997a1d77a60cd9cfb378c0104290",
            "3": "22400000000000000000000",
            "roundId": "27",
            "ticketNumber": "7",
            "player": "0x0d85c6375fd2997a1d77a60cd9cfb378c0104290",
            "rewardSize": "22400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000d"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000001d0000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc890000000000000000000000000000000000000000000005c283d4103941000000",
          "meta": {
            "blockID": "0x00d4f9548c3387af24040a6ff6a375b6cbc4daec1f96945988cbf784e0a7c790",
            "blockNumber": 13957460,
            "blockTimestamp": 1670097560,
            "txID": "0x35fb7b4b2586a0f8957dec8886b4eb95e1876e29b03a69bba6f0ef8fcaf4e756",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "29",
            "1": "13",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "27200000000000000000000",
            "roundId": "29",
            "ticketNumber": "13",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "27200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000013"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000001e0000000000000000000000008dc336f35c12854ad5f93f3ef75085ef40a4386100000000000000000000000000000000000000000000059725991ece28800000",
          "meta": {
            "blockID": "0x00d51b14d0e3bbf4b2cc901c46838e0206fae03a3564bd15a855715ddfd1ac2a",
            "blockNumber": 13966100,
            "blockTimestamp": 1670183960,
            "txID": "0xb1025d2893804c9fa4870119e56b9de81cea050570a768163ae7b2fea897b1aa",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "30",
            "1": "19",
            "2": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "3": "26400000000000000000000",
            "roundId": "30",
            "ticketNumber": "19",
            "player": "0x8dc336f35c12854ad5f93f3ef75085ef40a43861",
            "rewardSize": "26400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000008"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000001f0000000000000000000000000d85c6375fd2997a1d77a60cd9cfb378c010429000000000000000000000000000000000000000000000036e342998b8b0200000",
          "meta": {
            "blockID": "0x00d53cd4a984f2a4169d839f938364df9b3c93ba35c1df8d8965002ad7d3f71e",
            "blockNumber": 13974740,
            "blockTimestamp": 1670270360,
            "txID": "0x358227f3ca631e3eb87a16b1162e8b60443d84e0b79e23c8c50cb10a8429e401",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "31",
            "1": "8",
            "2": "0x0d85c6375fd2997a1d77a60cd9cfb378c0104290",
            "3": "16200000000000000000000",
            "roundId": "31",
            "ticketNumber": "8",
            "player": "0x0d85c6375fd2997a1d77a60cd9cfb378c0104290",
            "rewardSize": "16200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000d"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000021000000000000000000000000a28403d83d7ba9b15f661ca5e92b40491697211a00000000000000000000000000000000000000000000038ebad5cdc902800000",
          "meta": {
            "blockID": "0x00d55e94b6139bc3f702db7c0584b440d4e763ad330cdd91459d6af2db279630",
            "blockNumber": 13983380,
            "blockTimestamp": 1670356760,
            "txID": "0x78cf4266cccc195da55567169a8197b06108366f03c31f3567bd642b45b39c96",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "33",
            "1": "13",
            "2": "0xa28403d83d7ba9b15f661ca5e92b40491697211a",
            "3": "16800000000000000000000",
            "roundId": "33",
            "ticketNumber": "13",
            "player": "0xa28403d83d7ba9b15f661ca5e92b40491697211a",
            "rewardSize": "16800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000009"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000022000000000000000000000000bc4016a569bd1a36a041e694b6d11478afaa21850000000000000000000000000000000000000000000002b5e3af16b188000000",
          "meta": {
            "blockID": "0x00d580546f6ea92d5297b3d13cba2e2b604ef0eec113ef77a7f4f19b42edf85a",
            "blockNumber": 13992020,
            "blockTimestamp": 1670443160,
            "txID": "0xb2c5827ffdf7b887c1c533f4c96a2a942504cdfa4041ef1c51ec69ec7d9201b4",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "34",
            "1": "9",
            "2": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "3": "12800000000000000000000",
            "roundId": "34",
            "ticketNumber": "9",
            "player": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "rewardSize": "12800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000001f"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000240000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc8900000000000000000000000000000000000000000000066ffcbfd5e5a3000000",
          "meta": {
            "blockID": "0x00d5a214f2f0fa7488ce323b2e0af5560e638c125020a73c8ac635e70c55f8ee",
            "blockNumber": 14000660,
            "blockTimestamp": 1670529560,
            "txID": "0x509c335efa1fb12ace2d08e70d1dc69278660f1cc6f749f5034dcbd44c00683c",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "36",
            "1": "31",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "30400000000000000000000",
            "roundId": "36",
            "ticketNumber": "31",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "30400000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000260000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc890000000000000000000000000000000000000000000003ba1910bf341b000000",
          "meta": {
            "blockID": "0x00d5c3d29cf3dc9987e8e1bb0d8dc901649b6696fdb43000d67224429cf2273f",
            "blockNumber": 14009298,
            "blockTimestamp": 1670615960,
            "txID": "0xdb90dfb2fa9b26d4f772707ae1e4673640e4c209e9c25cc67202cb1cdba9f57f",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "38",
            "1": "5",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "17600000000000000000000",
            "roundId": "38",
            "ticketNumber": "5",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "17600000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000002800000000000000000000000022e14b199e0bd524fda275b5665056a4cd0675f60000000000000000000000000000000000000000000001b1ae4d6e2ef5000000",
          "meta": {
            "blockID": "0x00d5e5926a36f0bd71651fe59572be4eced1b3e664e21a833911a6fb281afc8d",
            "blockNumber": 14017938,
            "blockTimestamp": 1670702360,
            "txID": "0xedd12314cff7e2350f16bdfe0cc766c00999b914f27370a5ccf46f1525450f4d",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "40",
            "1": "5",
            "2": "0x22e14b199e0bd524fda275b5665056a4cd0675f6",
            "3": "8000000000000000000000",
            "roundId": "40",
            "ticketNumber": "5",
            "player": "0x22e14b199e0bd524fda275b5665056a4cd0675f6",
            "rewardSize": "8000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000001b"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000002a000000000000000000000000bc4016a569bd1a36a041e694b6d11478afaa21850000000000000000000000000000000000000000000007f64cd252a97f800000",
          "meta": {
            "blockID": "0x00d607521052eed07ed2eb4ee5e81c1e2517793130e9f9425320e5fb5a0cc42d",
            "blockNumber": 14026578,
            "blockTimestamp": 1670788760,
            "txID": "0x81f488494e70c4ab0f028ba87255453a66e4a88a69037c0da550a9758eaf6b45",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "42",
            "1": "27",
            "2": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "3": "37600000000000000000000",
            "roundId": "42",
            "ticketNumber": "27",
            "player": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "rewardSize": "37600000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000009"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000002c000000000000000000000000bc4016a569bd1a36a041e694b6d11478afaa218500000000000000000000000000000000000000000000079f905c6fd34e800000",
          "meta": {
            "blockID": "0x00d629123d577703199cd04e861c3c0d570ca326c5e531949653cdb8deeb6410",
            "blockNumber": 14035218,
            "blockTimestamp": 1670875160,
            "txID": "0x7ba77f895c9e18b7d39dc603ab0cc6dd5d48de2e260cafd1ced9ee4b31ce2910",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "44",
            "1": "9",
            "2": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "3": "36000000000000000000000",
            "roundId": "44",
            "ticketNumber": "9",
            "player": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "rewardSize": "36000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000018"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000002d000000000000000000000000bc4016a569bd1a36a041e694b6d11478afaa21850000000000000000000000000000000000000000000007caee97613e67000000",
          "meta": {
            "blockID": "0x00d64ad2328084b3ac77de01ff22baa2cd85f515fb859d251d35588642a9409d",
            "blockNumber": 14043858,
            "blockTimestamp": 1670961560,
            "txID": "0x4f23fc7b72921f400ec9dcaefe6e91d738cf7de7633722e71de8c249b8349ed8",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "45",
            "1": "24",
            "2": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "3": "36800000000000000000000",
            "roundId": "45",
            "ticketNumber": "24",
            "player": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "rewardSize": "36800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000001"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000002f000000000000000000000000e2c5f9d27e40a235bf05de37dfbb7670af8f87eb00000000000000000000000000000000000000000000002b5e3af16b18800000",
          "meta": {
            "blockID": "0x00d66c92850d8bf30f216d36c40dfc4d7ad7ed9a03a14dd8275b79526f426b1e",
            "blockNumber": 14052498,
            "blockTimestamp": 1671047960,
            "txID": "0x6f6988d7e45376ee00f21eb2424bca06e84e1bea4843c960e67e109851d627d3",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "47",
            "1": "1",
            "2": "0xe2c5f9d27e40a235bf05de37dfbb7670af8f87eb",
            "3": "800000000000000000000",
            "roundId": "47",
            "ticketNumber": "1",
            "player": "0xe2c5f9d27e40a235bf05de37dfbb7670af8f87eb",
            "rewardSize": "800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000f"
          ],
          "data": "0x0000000000000000000000000000000000000000000000000000000000000030000000000000000000000000bc4016a569bd1a36a041e694b6d11478afaa21850000000000000000000000000000000000000000000004e9acad5921c6800000",
          "meta": {
            "blockID": "0x00d68e520028f88333fdc0f00017b7d92706cc199d32f064306ff8877fde8c07",
            "blockNumber": 14061138,
            "blockTimestamp": 1671134360,
            "txID": "0x43f22956dd7f0fa1e2d49925095d7fa8bf747a21ce978d6dde27632c5e14a81b",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "48",
            "1": "15",
            "2": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "3": "23200000000000000000000",
            "roundId": "48",
            "ticketNumber": "15",
            "player": "0xbc4016a569bd1a36a041e694b6d11478afaa2185",
            "rewardSize": "23200000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000006"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000330000000000000000000000007d4039aaea09ad88386d8e1b663d19ccf9bda4fe00000000000000000000000000000000000000000000079f905c6fd34e800000",
          "meta": {
            "blockID": "0x00d6d1d28d59dfb124ae18160743a2ac848a17f226935176e3d48b891c113062",
            "blockNumber": 14078418,
            "blockTimestamp": 1671307160,
            "txID": "0xc92621fadd3e7e0b89f77123a45e9cd3b7a6323c14dc24c3dff38871b935149c",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "51",
            "1": "6",
            "2": "0x7d4039aaea09ad88386d8e1b663d19ccf9bda4fe",
            "3": "36000000000000000000000",
            "roundId": "51",
            "ticketNumber": "6",
            "player": "0x7d4039aaea09ad88386d8e1b663d19ccf9bda4fe",
            "rewardSize": "36000000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000370000000000000000000000001e36fb4ae0c6e2fa419e47e732fe5a92df74fc8900000000000000000000000000000000000000000000012f939c99edab800000",
          "meta": {
            "blockID": "0x00d71551efd88948c979fe29072156a0688439d7d2cab0c394903cc2db28cbb0",
            "blockNumber": 14095697,
            "blockTimestamp": 1671479960,
            "txID": "0x0f2ca99e84b009ddc37357b6ec2c3e533019de0cd117068a3d4666485fd413d7",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "55",
            "1": "5",
            "2": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "3": "5600000000000000000000",
            "roundId": "55",
            "ticketNumber": "5",
            "player": "0x1e36fb4ae0c6e2fa419e47e732fe5a92df74fc89",
            "rewardSize": "5600000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000001"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000003900000000000000000000000075fd2cde3b74a2ab4140cf2237de6abdfb89438100000000000000000000000000000000000000000000002b5e3af16b18800000",
          "meta": {
            "blockID": "0x00d737117fd6ff1c9a23d8dde99d0e7f035878bfa68913b052b9b8c701a79dba",
            "blockNumber": 14104337,
            "blockTimestamp": 1671566360,
            "txID": "0x719c9d34e48e736cc6feed3312a02a291df1db47e9156d336ec7e02ea6ee0854",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "57",
            "1": "1",
            "2": "0x75fd2cde3b74a2ab4140cf2237de6abdfb894381",
            "3": "800000000000000000000",
            "roundId": "57",
            "ticketNumber": "1",
            "player": "0x75fd2cde3b74a2ab4140cf2237de6abdfb894381",
            "rewardSize": "800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000005"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000003b0000000000000000000000007f94c17a24d4a6b131e71cd8aea3ad43196497fa0000000000000000000000000000000000000000000001043561a88293000000",
          "meta": {
            "blockID": "0x00d758d18e2d566ff2629d11d4da439c066e94c8f3476caf14a543e7c1feb135",
            "blockNumber": 14112977,
            "blockTimestamp": 1671652760,
            "txID": "0x96af7fb2a8daa68ae45ec38a62701e91490c9cba06a3e2d66c1d17465be70684",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "59",
            "1": "5",
            "2": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "3": "4800000000000000000000",
            "roundId": "59",
            "ticketNumber": "5",
            "player": "0x7f94c17a24d4a6b131e71cd8aea3ad43196497fa",
            "rewardSize": "4800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x000000000000000000000000000000000000000000000000000000000000000b"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000003d0000000000000000000000002563987a7ab35aaf6d155a38d184316cfa48b02d00000000000000000000000000000000000000000000038ebad5cdc902800000",
          "meta": {
            "blockID": "0x00d77a8dd9f41bc760ab07f65853dff21023dc0cb1850eb6e9662448e47901d0",
            "blockNumber": 14121613,
            "blockTimestamp": 1671739160,
            "txID": "0xaedbc154989fb2112617c81695ed13af3f17162d976650901391aa7ed2643b3e",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "61",
            "1": "11",
            "2": "0x2563987a7ab35aaf6d155a38d184316cfa48b02d",
            "3": "16800000000000000000000",
            "roundId": "61",
            "ticketNumber": "11",
            "player": "0x2563987a7ab35aaf6d155a38d184316cfa48b02d",
            "rewardSize": "16800000000000000000000"
          }
        }, {
          "address": "0x248888a841d35b6f916349a4b5d3945318f83e13",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000001"
          ],
          "data": "0x000000000000000000000000000000000000000000000000000000000000003e0000000000000000000000003c52c2c4831eddf2c473be9d27c173ac94a4edb800000000000000000000000000000000000000000000002b5e3af16b18800000",
          "meta": {
            "blockID": "0x00d79c4d1f4d39321f12a1bd9a9bb01ef34174de98bdeebc308d23cc7da2ab01",
            "blockNumber": 14130253,
            "blockTimestamp": 1671825560,
            "txID": "0x6767e424d684438dd9c68a5f1f0f5b465f8f868fae4a4521ab8cc94a6e9d77be",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "62",
            "1": "1",
            "2": "0x3c52c2c4831eddf2c473be9d27c173ac94a4edb8",
            "3": "800000000000000000000",
            "roundId": "62",
            "ticketNumber": "1",
            "player": "0x3c52c2c4831eddf2c473be9d27c173ac94a4edb8",
            "rewardSize": "800000000000000000000"
          }
        }, {
          "address": "0xADf5de2add674DdB53A959dEC67CaDfaf27CD26d",
          "topics": [
            "0xb2c277a463d014085336d410c23746161fed456c75e5cc33a430bfa6020c8db4",
            "0x0000000000000000000000000000000000000000000000000000000000000001"
          ],
          "data": "0x00000000000000000000000000000000000000000000000000000000000000450000000000000000000000007f94c17a24d4a6b131e71cd8aea3ad43196497fa0000000000000000000000000000000000000000000045498c2bb41a24800000",
          "meta": {
            "blockID": "0x00d79c4d1f4d39321f12a1bd9a9bb01ef34174de98bdeebc308d23cc7da2ab01",
            "blockNumber": 14208034,
            "blockTimestamp": 1672606989,
            "txID": "0x8c24a9641fbd59da9a6376d1fd76e4486e551b3b545a8424924abdd04a06ed8e",
            "txOrigin": "0xeb81edd9d9c6e5795ee381e7878fcb6d2a1e4fe9",
            "clauseIndex": 0
          },
          "decoded": {
            "0": "69",
            "1": "3",
            "2": "	0x7F94C17A24d4a6B131e71cd8AeA3AD43196497fA",
            "3": "327200000000000000000000",
            "roundId": "69",
            "ticketNumber": "3",
            "player": "	0x7F94C17A24d4a6B131e71cd8AeA3AD43196497fA",
            "rewardSize": "327200000000000000000000"
          }
        })
        logs = logs.slice(15);
        console.log(logs)
        logsV2 = logsV2.concat(logs);
        console.log(logsV2)
        // logs.reverse();
        logsV2.sort((a, b) => {
          return a.round - b.round;
        });
        // console.log(logs.slice(5))
        let res = logsV2.map((log) => ({
          date: new Date(log.meta.blockTimestamp * 1000),
          account: log.decoded.player,
          amount: parseInt(
            (
              BigInt(log.decoded.rewardSize) / BigInt(Math.pow(10, 16))
            ).toString()
          ) / 100,
          round: parseInt(log.decoded.roundId),
          ticketNumber: parseInt(log.decoded.ticketNumber),
          txId: log.meta.txID
        }));
        resolve(res);
      });
    } catch (error) {
      console.log("getRewardEventHistory: ", error);
      resolve([]);
    }
  });

export const approve = (owner, spender, balance, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [{
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [{
          internalType: "bool",
          name: "",
          type: "bool",
        }, ],
        stateMutability: "nonpayable",
        type: "function",
      };

      const method = connex.thor.account(config.tokenAddress).method(abi);
      const tx = method.asClause(spender, parseUnits(balance + "", "ether"));

      if (window.connex) {
        // Connex V1
        connex = window.connex;

        const transactionInfo = await connex.vendor
          .sign('tx')
          // .delegate(delegationHandler)
          .signer(owner)
          .request([tx]);

        return transactionInfo;
      } else {
        // delegate url
        const delegateUrl = 'https://sponsor.vechain.energy/by/55'

        connex.vendor
          .sign("tx", [tx])
          .signer(owner)
          .comment("")
          .delegate(delegateUrl)
          .request()
          .then((res) => {
            resolve("Success");
          })
          .catch((error) => {
            console.log("approve: ", error);
            resolve("Failed");
          });
      }
    } catch (error) {
      console.log("approve: ", error);
      resolve("Failed");
    }
  });

export const claimTicket = (account, cnt, connex) =>
  new Promise(async (resolve, reject) => {
    try {
      const abi = {
        inputs: [{
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        }, ],
        name: "claimTicket",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      };

      const method = connex.thor.account(config.lotteryAddress).method(abi);
      const tx = method.asClause(cnt);

      // delegate url
      const delegateUrl = 'https://sponsor.vechain.energy/by/55'

      connex.vendor
        .sign("tx", [tx])
        .signer(account)
        .delegate(delegateUrl)
        .comment("")
        .request()
        .then((res) => {
          resolve("Success");
        })
        .catch((error) => {
          console.log("claimTicket: ", error);
          resolve("Failed");
        });
    } catch (error) {
      console.log("claimTicket: ", error);
      resolve("Failed");
    }
  });