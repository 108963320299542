import "./buy-ticket-modal.scss";

import { useAccount, useChainState } from "@vechain.energy/use-vechain";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { setPrizePot } from "../../slices/prizePotSlice";
import { setMyTickets } from "../../slices/myTicketsSlice";
import {
  getAllowance,
  getTicketPrice,
  WoVBalanceOf,
  approve,
  claimTicket,
  getPlayerNumber,
  getMyTicketNumber,
} from "../../utils/vechain";

import config from "../../config/config.json";
import { setTotalTicketCnt } from "../../slices/totalTicketCntSlice";

const buyTicketModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(70, 70, 70, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    border: "none",
    backgroundColor: "#27262c",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: 0,
    borderRadius: 24,
    inset: "0",
  },
};

export default function BuyTicketModal({ isOpen, onRequestClose }) {
  const { account, connect } = useAccount();
  const { connex } = useChainState();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [accountBalance, setAccountBalance] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [ticketCnt, setTicketCnt] = useState(1);

  const handleBuyButton = async () => {
    if (account) {
      if (allowance < ticketPrice * ticketCnt) {
        await approve(account, config.lotteryAddress, "1000000000000", connex);
        setTimeout(async () => {
          setAllowance(
            (await getAllowance(account, config.lotteryAddress, connex))
          );
        }, [10000]);
      } else {
        alert.removeAll();
        alert.info("Interfacing with wallet...", { timeout: 1000000000 });
        let res = await claimTicket(account, ticketCnt, connex);
        alert.removeAll();
        if (res === "Failed") {
          alert.error(res);
        } else {
          let alertId = alert.info("Transaction is pending...", {
            timeout: 100000,
          });

          setTimeout(async () => {
            let b = await WoVBalanceOf(config.lotteryAddress, connex);
            dispatch(setPrizePot((b * 0.7).toFixed(1)));

            let total = await getPlayerNumber(connex);
            dispatch(
              setMyTickets(await getMyTicketNumber(account, total, connex))
            );
            dispatch(setTotalTicketCnt(total))

            alert.remove(alertId);

            let msg = "Congrats, you got a ticket! Good luck!";
            if (ticketCnt > 1) {
              msg = `Congrats, you got ${ticketCnt} tickets! Good luck!`;
            }
            alert.success(msg);
          }, 10000);
        }

        onRequestClose();
      }
    } else {
      connect();
    }
  };

  const handleChangeTicketCnt = (e) => {
    let cnt = parseInt(e.target.value);
    if (e.target.value === "" || cnt === 0) {
      cnt = 0;
      e.target.value = "";
    }
    if (cnt > accountBalance) {
      cnt = parseInt(accountBalance);
    }
    setTicketCnt(cnt);
  };

  const init = async () => {
    if (connex && account) {
      setAccountBalance(await WoVBalanceOf(account, connex));
      setTicketPrice(await getTicketPrice(connex));
      setAllowance(await getAllowance(account, config.lotteryAddress, connex));
    }
  };

  const handleSetTicketButtonClick = (cnt) => {
    if (cnt === "max") {
      setTicketCnt(parseInt(accountBalance));
    } else {
      if (cnt > accountBalance) {
        setTicketCnt(parseInt(accountBalance));
      } else {
        setTicketCnt(cnt);
      }
    }
  };

  useEffect(() => {
    init();
  }, [connex, account]); //eslint-disable-line

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
        alert.removeAll();
      }}
      style={buyTicketModalStyle}
      ariaHideApp={false}
    >
      <div className="buy-ticket-modal">
        <div className="modal-header">
          <div>{allowance >= ticketPrice * ticketCnt ? "WoV Lottery Tickets" : "Approve"}</div>
          <button onClick={onRequestClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="modal-body">
          {allowance >= ticketPrice * ticketCnt ? (
            <div className="modal-content">
              <div className="row">
                <div className="left">Buy Ticket(s)</div>
                <div className="ticket-number">
                  <input
                    type="text"
                    value={ticketCnt}
                    onChange={handleChangeTicketCnt}
                  />
                </div>
              </div>
              <div className="buy-buttons">
                <button onClick={() => handleSetTicketButtonClick(10)}>
                  10
                </button>
                <button onClick={() => handleSetTicketButtonClick(25)}>
                  25
                </button>
                <button onClick={() => handleSetTicketButtonClick(50)}>
                  50
                </button>
                <button onClick={() => handleSetTicketButtonClick("max")}>
                  MAX
                </button>
              </div>
              <div className="row">
                <div className="left">Balance available</div>
                <div className="right white">{accountBalance} WoV</div>
              </div>
              <div className="row">
                <div className="left">You will pay</div>
                <div className="right white">{ticketPrice * ticketCnt} WoV</div>
              </div>
              <div className="line"></div>
              <div className="row">
                <div className="left">New balance</div>
                <div className="right white">
                  {(accountBalance - ticketPrice * ticketCnt).toFixed(2)} WoV
                </div>
              </div>
            </div>
          ) : (
            "You need to approve your tokens first."
          )}
          <button
            onClick={handleBuyButton}
          >
            {!account
              ? "Connect"
              : allowance < ticketPrice * ticketCnt
              ? "Approve WoV"
              : "Purchase Ticket(s)"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
