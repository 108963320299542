import { createSlice } from '@reduxjs/toolkit'

const initialState = 0

export const prizePotSlice = createSlice({
  name: 'prizePot',
  initialState,
  reducers: {
    setPrizePot: (state, action) => {
      state = action.payload
      return state 
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPrizePot } = prizePotSlice.actions

export default prizePotSlice.reducer