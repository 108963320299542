import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const myTicketsSlice = createSlice({
  name: 'myTickets',
  initialState,
  reducers: {
    setMyTickets: (state, action) => {
      state = action.payload
      return state 
    }
  },
})

// Action creators are generated for each case reducer function
export const { setMyTickets } = myTicketsSlice.actions

export default myTicketsSlice.reducer