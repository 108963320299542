import { configureStore } from '@reduxjs/toolkit'
import prizePotReducer from './slices/prizePotSlice'
import myTicketsReducer from './slices/myTicketsSlice'
import totalTicketCntReducer from './slices/totalTicketCntSlice'
import leftTimeReducer from './slices/leftTimeSlice'

export const store = configureStore({
  reducer: {
    prizePot: prizePotReducer,
    myTickets: myTicketsReducer,
    totalTicketCnt: totalTicketCntReducer,
    leftTime: leftTimeReducer,
  },
})