import "./banner.scss";

import React, { useState } from "react";
// import CountUp from 'react-countup';
import BuyTicketModal from "../BuyTicketModal/BuyTicketModal";
import { useSelector } from "react-redux";
import { formatWov } from "../../utils/utils";

export default function Banner() {
  const prizePot = useSelector((state) => state.prizePot);
  const leftTime = useSelector((state) => state.leftTime);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <section id='banner'>

      <div className='bg-star'></div>
      <img className='veLottery__logo' src="/images/velottery__logo2.png" alt="logo" />
        <h4>Current Jackpot</h4>
        <div className="prize-pool-balance">{formatWov(prizePot)} WoV</div>
        <div className="buy-button">
          <img src="/images/ticket-button.png" alt="ticket button" />
          
          <button onClick={() => setModalOpen(true)}>
            Buy your tickets now!
          </button>
        </div>
        
        
        <div className="left-time" show>
        <span className="big">{leftTime.day}</span>
          <span>d </span>
          <span className="big">{leftTime.hour}</span>
          <span>h </span>
          <span className="big">{leftTime.minute}</span>
          <span>m </span>
          <span className="big">{leftTime.second}</span>
          <span>s</span>
          {" until the draw"}
        </div>
        

        <BuyTicketModal
          onRequestClose={() => setModalOpen(false)}
          isOpen={modalOpen}
        />

    </section>
  );
}
