import "./get-ticket.scss";

import React, { useEffect, useState } from "react";
// import CountUp from "react-countup";
import BuyTicketModal from "../BuyTicketModal/BuyTicketModal";
import { useAccount, useChainState } from "@vechain.energy/use-vechain";

import {
  getLotteryEndDate,
  getRewardEventHistory,
  getTotalJackpot,
  getTotalPlayers,
  getTotalRewardCnt,
  getTotalRound,
} from "../../utils/vechain";
import { calcTime, dateToString, formatWov } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Congratulation from "../Congratulation/Congratulation";
import { setPrizePot } from "../../slices/prizePotSlice";
import { setMyTickets } from "../../slices/myTicketsSlice";
import { setLeftTime } from "../../slices/leftTimeSlice";

export default function GetTicket() {
  const { connex } = useChainState();
  const { account } = useAccount();
  const dispatch = useDispatch();
  const prizePot = useSelector((state) => state.prizePot);
  const myTickets = useSelector((state) => state.myTickets);
  const totalTicketCnt = useSelector((state) => state.totalTicketCnt);

  const [modalOpen, setModalOpen] = useState(false);
  const [pageStep, setPageStep] = useState(1);
  const [winnerList, setWinnerList] = useState([]);

  const [lotteryEndTime, setLotteryEndTime] = useState("");
  const [currentRound, setCurrentRound] = useState(1);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [winningNumber, setWinningNumber] = useState(0);
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);

  let endTime = 0;

  useEffect(() => {
    init();
  }, [connex, account]); //eslint-disable-line

  const init = async () => {
    if (connex) {
      let ed = await getLotteryEndDate(connex);

      endTime = ed;
      dispatch(setLeftTime(calcTime(ed)));

      setLotteryEndTime(dateToString(ed));

      setInterval(setTime, 1000);

      let rcnt = await getTotalRewardCnt(connex);
      console.log(rcnt)
      let list = await getRewardEventHistory(1, rcnt, connex);
      // setIsLast(list.length < 3);
      setWinnerList(list.reverse());

      setCurrentRound(await getTotalRound(connex));
      setTotalJackpot(await getTotalJackpot(connex));
      setTotalPlayers(await getTotalPlayers(connex));
    }
  };

  const setTime = async () => {
    let t = calcTime(endTime);
    
    dispatch(setLeftTime(t));
    if (t.day === 0 && t.hour === 0 && t.minute === 0 && t.second === 2) {
      let rcnt = await getTotalRewardCnt(connex);
      let result = await getRewardEventHistory(
        Math.max(0, rcnt - 1),
        1,
        connex
      );

      if (result.length === 0) {
        return;
      }
      let tmp = winnerList.map((a) => a);
      tmp.unshift(result[0]);
      setWinnerList(tmp);

      // setTotalRewardCnt(rcnt);

      setIsWinner(result[0].account === account);
      setWinningNumber(result[0].ticketNumber);
      setShowConfetti(true);

      setTimeout(async () => {
        setIsWinner(false);
        setWinningNumber(0);
        setShowConfetti(false);
      }, 10000);

      dispatch(setPrizePot(0));
      dispatch(setMyTickets([]));
    }
  };



  return (
    <section id="get-ticket">
      <div className="page-buttons">
        <button
          className={pageStep === 1 ? "active" : ""}
          onClick={() => setPageStep(1)}
        >
          Info
        </button>
        <button
          className={pageStep === 2 ? "active" : ""}
          onClick={() => setPageStep(2)}
        >
          Winners
        </button>
      </div>
      {pageStep === 1 && (
        <div className="info">
          <h2>Get your tickets now!</h2>
          <div className="ticket-form">
            <div className="form-header">
              <h3>Next Draw</h3>
              <p>
                # {currentRound} | Draw: {lotteryEndTime}
              </p>
            </div>
            <div className="form-body">
              <div className="left">Prize Pot</div>
              <div className="right count">{formatWov(prizePot)} WoV</div>
              <div className="left">Tickets Purchased</div>
              <div className="right count">{totalTicketCnt}</div>
              <div className="left">Tickets Owned</div>
              <div className="right">{myTickets.length}</div>
              <div className="left">Your ticket(s) number</div>
              <div className="right">
                {myTickets.length === 0 ? (
                  <button onClick={() => setModalOpen(true)}>Buy Ticket (each 1000 WoV)</button>
                ) : (
                  myTickets.map((tn, index) => (index === 0 ? "" : ", ") + tn)
                )}
              </div>
            </div>
          </div>

          <BuyTicketModal
            onRequestClose={() => setModalOpen(false)}
            isOpen={modalOpen}
          />

          <div className="stats">
            <div className="stat">
              <div className="title">Total lotteries played</div>
              <div className="value">{currentRound}</div>
            </div>
            <div className="stat">
              <div className="title">Total players</div>
              <div className="value">{totalPlayers}</div>
            </div>
            <div className="stat">
              <div className="title">Total played</div>
              <div className="value">{formatWov(totalJackpot)} WoV</div>
            </div>
            <div className="stat">
              <div className="title">WoV burned</div>
              <div className="value">{formatWov(totalJackpot * 0.2)} WoV</div>
            </div>
            <div className="stat">
              <div className="title">WoV prizes paid</div>
              <div className="value">{formatWov(totalJackpot * 0.8)} WoV</div>
            </div>
          </div>

          <div className="prize-distribution">
            Lottery prize distribution <br />
            <span>80% to the lottery winner - 20% burned</span>
          </div>
        </div>
      )}
      {pageStep === 2 && (
        <div className="winners">
          <h2>Winners</h2>
          <div className="list-container">
            <ul>
              <li className="list-header">
                <div className="round">Round</div>
                <div className="when">Date</div>
                <div className="name">
                  <div className="cup"></div>
                  <div className="account">Winner</div>
                </div>
                <div className="ticket-number">Winning Number</div>
                <div className="prize">Prize</div>
                <div className="prize">txId</div>
              </li>
              {winnerList.map((winner, index) => (
                <li key={index}>
                  <div className="round">#{winner.round}</div>
                  <div className="when">{`${winner.date.getDate()}/${
                    winner.date.getMonth() + 1
                  }/${winner.date.getFullYear()}`}</div>
                  <div className="name">
                    <div className="cup">
                      {winner.account === account && " 🏆"}
                    </div>
                    <div className="account">
                      {winner.account.slice(0, 6)}...
                      {winner.account.slice(
                        winner.account.length - 3,
                        winner.account.length
                      )}
                    </div>
                  </div>
                  <div className="ticket-number">{winner.ticketNumber}</div>
                  <div className="prize">{formatWov(winner.amount)} WOV</div>
                  <div className=""> <a href={'https://vechainstats.com/transaction/' + winner.txId}>{winner.txId.slice(0,6)}...</a></div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {showConfetti && (
        <Congratulation isWinner={isWinner} winningNumber={winningNumber} />
      )}
    </section>
  );
}
