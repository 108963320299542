import './profile-modal.scss'

import React from 'react'
import ReactModal from 'react-modal';
import { useAccount, useChainState } from '@vechain.energy/use-vechain';
import { useEffect } from 'react';
import { getUserProfile } from '../../utils/vechain';
import { useState } from 'react';

const profileModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(70, 70, 70, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    border: "none",
    backgroundColor: "#27262c",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: 0,
    borderRadius: 12,
    inset: "0",
  },
};


export default function ProfileModal({ isOpen, onRequestClose }) {
  
  const { account } = useAccount();
  const { connex } = useChainState()

  const [userProfile, setUserProfile] = useState({
    gamesPlayed: 0,
    gamesWon: 0,
    totalTicketPurchased: 0,
    totalJackpotWon: 0
  })
  
  useEffect(() => {
    init()
  }, [connex, account]) //eslint-disable-line

  const init = async () => {
    if(connex && account) {
      setUserProfile(await getUserProfile(account, connex))
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={profileModalStyle}
      ariaHideApp={false}
    >
      <div className="profile-modal">
        <div className='title'>Dashboard</div>
        <div className='wallet'>
          <div className='address'>
            {account && account.slice(0, 7)}
            ...
            {account && account.slice(account.length - 5, account.length)}
          </div>
          <button onClick={() => { navigator.clipboard.writeText(account) }}>
            <i className="fa-regular fa-clone"></i>
          </button>
        </div>

        <div className='row'>
          <div>Games Played</div>
          <div>{userProfile.gamesPlayed}</div>
        </div>

        <div className='row'>
          <div>Games Won</div>
          <div>{userProfile.gamesWon}</div>
        </div>
        
        <div className='row'>
          <div>Total Tickets Purchased</div>
          <div>{userProfile.totalTicketPurchased}</div>
        </div>
        
        <div className='row'>
          <div>Total prizes won</div>
          <div>{userProfile.totalJackpotWon} WoV</div>
        </div>
      </div>
    </ReactModal>
  )
}
