import { createSlice } from "@reduxjs/toolkit";

const initialState = { day: 0, hour: 0, minute: 0, second: 0 };

export const leftTimeSlice = createSlice({
  name: "leftTime",
  initialState,
  reducers: {
    setLeftTime: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLeftTime } = leftTimeSlice.actions;

export default leftTimeSlice.reducer;
