import config from '../config/config.json'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const calcTime = (timestamp) => {
  let diff = timestamp - Date.now();

  while(diff < 0) {
    diff += config.lotteryPeriod;
  }

  diff = diff % config.lotteryPeriod

  diff = parseInt(diff / 1000);
  
  let second = diff % 60
  diff = parseInt(diff / 60)

  return {
    day: parseInt(diff / 60 / 24),
    hour: parseInt(diff % (1440) / 60),
    minute: diff % 60,
    second: second
  };
};

export const dateToString = (timestamp) => {
  while(timestamp < Date.now()) {
    timestamp += config.lotteryPeriod
  }
  let d = new Date(timestamp)
  let h = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`
  let m = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`
  return `${d.getFullYear()} ${months[d.getMonth()]} ${d.getDate()} ${h}:${m}`
}

export const formatWov = amount => {
  amount = Number(amount)
  if(amount < 1000) {
    return amount.toFixed(1)
  }
  else if(amount < 1000000) {
    return `${(amount / 1000).toFixed(1)} K`
  }
  else {
    return `${(amount / 1000000).toFixed(1)} M`
  }
}