import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { VeChainProvider } from '@vechain.energy/use-vechain'
import { positions, Provider as AlertProvider } from "react-alert";
// import AlertTemplate from "react-alert-template-basic";
import AlertTemplate from './components/AlertTemplate/AlertTemplate'
import { store } from './store'
import { Provider } from 'react-redux'

import config from './config/config.json'

const vechainConfig = config.mode === 'dev' ? {
  node: 'https://testnet.veblocks.net/',
  network: 'test'
} : {
  node: 'https://mainnet.veblocks.net',
  network: 'main'
}

const alertConfig = {
  timeout: 10000,
  position: positions.TOP_CENTER
};


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertConfig}>
        <VeChainProvider config={vechainConfig}>
          <App />
        </VeChainProvider>
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();