import "./alert-template.scss";

import React from "react";

export default function AlertTemplate({ style, options, message, close }) {
  return (
    <div style={style} className={`alert-template ${options.type}`}>
      {/* {options.type === "info" && "!"}
    {options.type === "success" && ":)"}
    {options.type === "error" && ":("} */}
      {message}
      {/* <button onClick={close}>X</button> */}
    </div>
  );
}
