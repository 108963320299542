import { createSlice } from '@reduxjs/toolkit'

const initialState = 0

export const totalTicketCntSlice = createSlice({
  name: 'totalTicketCnt',
  initialState,
  reducers: {
    setTotalTicketCnt: (state, action) => {
      state = action.payload
      return state 
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTotalTicketCnt } = totalTicketCntSlice.actions

export default totalTicketCntSlice.reducer