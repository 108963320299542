import './layout.scss'

import React from 'react'
import Header from '../Header/Header'
// import Footer from '../Footer/Footer'

export default function Layout({ children }) {
  return (
    <div id='layout'>
      <h2 style={{display: 'none'}}>We're down for planned maintenance. We'll be back soon!</h2>
      <div style={{display: 'visible'}}>

      <Header />
      <main>
        {children}
      </main>
      {/*<Footer />*/}
      </div>
    </div>
  )
}
